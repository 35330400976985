import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import { graphql } from 'gatsby'
import Layout from '../layout'
import { SEO, Action, Articles, MainUpdate, MainHeadings } from '../components'
import SearchDocs from '../assets/icons/search-docs.svg'
import ChatWithUs from '../assets/icons/chat-with-us.svg'

import PlatformUpdatesOgImage from '../assets/images/platform-updates-seo-image.png'

export const query = graphql`
  query supportQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1
    ) {
      nodes {
        fields {
          index
        }
        frontmatter {
          slug
          author
          title
          date(formatString: "DD MMMM, YYYY")
          ogimage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const Support = ({ data }) => {
  const isWide = useMedia({ minWidth: 870 })

  const {
    frontmatter: { title, slug, author, date, ogimage },
    fields: { index },
  } = data.allMarkdownRemark.nodes[0]

  const cards = [
    {
      icon: <SearchDocs />,
      title: 'Search the docs',
      description: 'We keep our API and SDKs documentation up-to-date.',
      link: {
        url: process.env.GATSBY_DOCS_URL,
        label: 'Go to the docs',
      },
    },
    {
      icon: <ChatWithUs />,
      title: 'Chat with us',
      description: 'We love chatting! Let’s discuss implementation details.',
      link: {
        url: '#chat-with-us',
        onClick: e => {
          e.preventDefault()
          if (window.LC_API) {
            window.LC_API.open_chat_window && window.LC_API.open_chat_window()
          }
          return false
        },
        label: 'Talk nerdy to us',
      },
    },
  ]

  const articles = [
    {
      title: 'How to monetize LiveChat apps?',
      description:
        'Monetizing your app is few clicks away. LiveChat customers will be able to buy it directly in the Marketplace.',
      link: `${process.env.GATSBY_DOCS_URL}/monetization/how-to-monetize-apps/`,
      linkLabel: '',
    },
    {
      title: (
        <>
          How to authorize
          <br /> API calls?
        </>
      ),
      description:
        'LiveChat uses OAuth 2.0 protocol for authentication and authorization for all of its services. Learn how to use it.',
      link: `${process.env.GATSBY_DOCS_URL}/getting-started/authorization/`,
    },
    {
      title: 'What is the App Review process?',
      description:
        'Our goal is to provide trusted space for high quality LiveChat apps. See what is the process behind it.',
      link: `${process.env.GATSBY_DOCS_URL}/monetization/app-review-process/`,
    },
    {
      title: (
        <>
          How to build <br /> LiveChat app?
        </>
      ),
      description:
        'Learn how to customize the interface of LiveChat, implement your own chat widget, or automate a variety of tasks.',
      link: `${process.env.GATSBY_DOCS_URL}/getting-started/guides/`,
    },
    {
      title: 'How to install LiveChat widget?',
      description:
        'Installing LiveChat is simple and only takes a few minutes. See instructions for both web and mobile.',
      link: `${
        process.env.GATSBY_DOCS_URL
      }/getting-started/installing-livechat/`,
    },
    {
      title: <>Can I build custom chat widget?</>,
      description:
        'Yes! We give you access to the same SDK we use to build our native widget. See for yourself!',
      link: `${
        process.env.GATSBY_DOCS_URL
      }/extending-ui/extending-chat-widget/customer-sdk/`,
    },
  ]

  return (
    <Layout>
      <SEO
        title="Platform Support"
        description="Learn how to customize the interface of LiveChat, implement your own chat widget, or automate a variety of tasks."
        image={PlatformUpdatesOgImage}
      />
      <div className="u-bg-black-3 u-Pb-md md:u-Pb-2xl">
        <MainHeadings title="Platform Support" />
        <section className="o-container u-Mt-md md:u-Mt-xl">
          <div className="o-columns v--two v--responsive">
            {cards?.map((card, i) => {
              return (
                <div
                  key={`${card.title}-${i}`}
                  className="u-bg-black2 u-P-md u-rounded"
                >
                  <div className="u-Mb-xs">{card.icon}</div>
                  <h2 className="u-text-p4">{card.title}</h2>
                  <h3 className="u-text-p6 u-w-full">{card.description}</h3>
                  <a
                    href={card.link.url}
                    onClick={card.link.onClick}
                    className={`c-link u-text-livechat u-text-p6-bold`}
                  >
                    {card.link.label}
                  </a>
                </div>
              )
            })}
          </div>
        </section>
      </div>

      <div className="u-bg-black2 u-Py-md md:u-Py-2xl">
        <Articles title="Popular questions" items={articles} />

        <div className="u-text-center">
          <a
            href={process.env.GATSBY_DOCS_URL}
            className="c-btn v--livechat u-text-white u-text-p6-bold"
          >
            Browse all articles
          </a>
        </div>
      </div>

      <div className="u-bg-black-3 u-Py-md md:u-Py-2xl">
        <section className="o-container">
          <h3 className="u-text-p3 u-mb-xl">Platform Updates</h3>
        </section>
        <MainUpdate
          wider
          eyeCatcher="Latest update"
          title={title}
          author={author}
          date={date}
          image={ogimage}
          slug={slug}
          index={index}
        />
      </div>

      <div className="u-bg-black2 u-Pt-sm u-Pb-sm md:u-Py-xl">
        <Action
          image="moneyChart"
          title={
            <>
              Join Developer Program
              <br /> and start selling apps
            </>
          }
          button={{
            link: process.env.GATSBY_CONSOLE_URL,
            label: isWide ? 'Join Developer Program' : 'Join Dev Program',
          }}
          link={{ link: '/', label: 'browse the docs' }}
        />
      </div>
    </Layout>
  )
}

Support.propTypes = {
  data: PropTypes.object,
}

export default Support
